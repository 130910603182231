import React from "react";

export default function NoTodos() {
  return (
    <div>
      <div className="no-todos-container">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          width="732.85335"
          height="614.57198"
          viewBox="0 0 732.85335 614.57198"
        >
          <polygon
            points="322.091 232.107 486.005 91.904 486.765 93.962 323.429 233.671 322.091 232.107"
            fill="#2f2e41"
          />
          <circle cx="499.94653" cy="99.61248" r="24.02225" fill="#6c63ff" />
          <path
            d="M935.44874,295.66948H716.11068a7.15334,7.15334,0,1,1,0-14.30667H935.44874a7.15334,7.15334,0,1,1,0,14.30667Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#6c63ff"
          />
          <path
            d="M935.44874,323.93589H716.11068a7.15369,7.15369,0,1,1,0-14.30738H935.44874a7.15369,7.15369,0,1,1,0,14.30738Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#6c63ff"
          />
          <path
            d="M811.18845,352.2023H716.11068a7.15369,7.15369,0,0,1,0-14.30738h95.07777a7.15369,7.15369,0,0,1,0,14.30738Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#6c63ff"
          />
          <circle cx="540.43422" cy="281.80018" r="16.46545" fill="#e6e7e8" />
          <path
            d="M912.24464,461.12382H761.9048a4.90308,4.90308,0,1,1,0-9.80615H912.24464a4.90308,4.90308,0,1,1,0,9.80615Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#e6e7e8"
          />
          <path
            d="M799.24464,480.49834H761.9048a4.90333,4.90333,0,0,1,0-9.80665h37.33984a4.90333,4.90333,0,0,1,0,9.80665Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#e6e7e8"
          />
          <circle cx="487.88315" cy="446.45868" r="16.46545" fill="#e6e7e8" />
          <path
            d="M859.69357,625.78232H709.35373a4.90308,4.90308,0,1,1,0-9.80615H859.69357a4.90308,4.90308,0,1,1,0,9.80615Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#e6e7e8"
          />
          <path
            d="M799.69357,645.15683H709.35373a4.90332,4.90332,0,1,1,0-9.80664h90.33984a4.90332,4.90332,0,1,1,0,9.80664Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#e6e7e8"
          />
          <path
            d="M774.52243,664.53134h-65.1687a4.90332,4.90332,0,1,1,0-9.80664h65.1687a4.90332,4.90332,0,0,1,0,9.80664Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#e6e7e8"
          />
          <path
            d="M275.61081,719.36245c-25.71428-12.185-44.86749-36.70044-41.87969-63.64221,23.06911,18.68018,54.47693,29.11157,86.90036,28.86194,12.62853-.09717,26.77178-1.3819,35.94872,5.70429,5.7102,4.40911,8.286,11.4104,8.61145,18.38812.32535,6.97809-1.3663,14.04034-3.04834,21.01043l.5115,1.65162C332.463,733.25869,301.32509,731.54745,275.61081,719.36245Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#f2f2f2"
          />
          <path
            d="M234.36427,655.65341c9.9453,19.40582,27.18807,35.57983,48.617,45.04706a51.442,51.442,0,0,0,14.82859,4.35675,33.5242,33.5242,0,0,0,15.42441-2.01624c4.6572-1.67114,9.248-3.84753,14.18726-4.5094a18.15754,18.15754,0,0,1,13.72064,3.69928c4.564,3.54926,7.28635,8.4607,9.7533,13.36029,2.73907,5.44007,5.48361,11.1366,10.84707,14.844.64987.44922-.25516,1.38348-.904.935-9.33151-6.45025-10.6781-18.11407-18.093-25.92639-3.46-3.64538-8.2622-6.28839-14.02652-5.86334-5.04061.37164-9.77649,2.61249-14.47891,4.33984a35.97734,35.97734,0,0,1-15.04559,2.46863,48.26278,48.26278,0,0,1-15.00934-3.81634,103.57874,103.57874,0,0,1-28.39854-17.67023,96.83465,96.83465,0,0,1-22.69579-28.81116c-.34354-.67035.93219-1.1037,1.27348-.43774Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M275.803,697.96566A20.13522,20.13522,0,0,1,253.1651,703.72c-.74841-.31488-.13725-1.41156.61214-1.09625a18.64074,18.64074,0,0,0,21.04919-5.39032c.52592-.63965,1.49947.09625.97653.7323Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M310.97267,704.15756a27.07555,27.07555,0,0,0-11.64455-18.87848c-.64785-.45136.25695-1.3858.904-.935a28.1523,28.1523,0,0,1,12.07028,19.67011c.092.75189-1.23826.89117-1.32976.14331Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M247.98179,676.00784a9.15427,9.15427,0,0,0,3.5295-8.18225c-.10275-.7497,1.22768-.88818,1.32976-.14331a10.24883,10.24883,0,0,1-3.88272,9.05786.76078.76078,0,0,1-.94028.10138.54539.54539,0,0,1-.03626-.83362Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M318.57048,598.7565c.07928.46539.15858.93078.24786,1.40008a101.68616,101.68616,0,0,0,5.02132,18.29029c.1647.46106.33964.92553.5193,1.38031a110.23454,110.23454,0,0,0,25.28423,38.16406,115.86438,115.86438,0,0,0,14.46014,12.09411c7.16547,5.07056,15.43543,10.02442,19.97,16.85584a18.14549,18.14549,0,0,1,1.28852,2.20679l-20.42969,41.33526c-.09751.077-.18509.15778-.28281.23523l-.73392,1.67755c-.284-.17157-.57388-.35522-.85788-.52679-.16525-.09888-.326-.20691-.49124-.30579-.10873-.06884-.21728-.13812-.32037-.19433-.03629-.02277-.07236-.046-.09851-.06543-.103-.05622-.19116-.11872-.284-.17157q-2.436-1.53378-4.85342-3.10535c-.01022-.00342-.01022-.00342-.0159-.016-12.25415-8.01282-23.7793-17.06732-33.24829-27.5141-.28476-.31439-.58-.63171-.85541-.965A103.13233,103.13233,0,0,1,311.62919,684.383a88.693,88.693,0,0,1-4.78584-9.06677,73.20117,73.20117,0,0,1-6.36935-25.47949c-1.15389-17.643,4.07974-35.88617,17.09125-50.00916C317.89839,599.46658,318.22714,599.11422,318.57048,598.7565Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#f2f2f2"
          />
          <path
            d="M319.093,599.0383c-5.69055,21.82177-3.72984,44.83679,5.90511,64.42a40.428,40.428,0,0,0,8.1528,11.69,27.28818,27.28818,0,0,0,12.913,6.60571c4.61807,1.09339,9.52841,1.72248,13.67447,3.824a14.41552,14.41552,0,0,1,7.76837,10.535c1.01441,5.471-.27212,11.10462-1.74188,16.59052-1.63187,6.091-3.43326,12.40246-1.92737,18.43872.18247.73138-1.12756,1.03595-1.30975.30566-2.62-10.50207,4.26749-21.12121,4.00445-31.7453-.12274-4.9574-1.929-9.78827-6.53241-12.53546-4.02545-2.40234-9.08807-3.055-13.778-4.12506a29.57157,29.57157,0,0,1-12.93561-6.01782,37.74519,37.74519,0,0,1-8.654-11.3291,88.27731,88.27731,0,0,1-9.30014-30.29853,97.48328,97.48328,0,0,1,2.51119-36.67364c.19656-.75379,1.445-.4336,1.24975.31531Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M321.47508,657.26852c-8.40434,2.63281-17.32017-.517-20.84408-7.32416-.34716-.67059.85276-1.2713,1.20039-.59979,3.30128,6.377,11.55205,9.23749,19.40829,6.77631.82677-.259,1.05747.89007.23542,1.14758Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M343.61481,681.48178a28.74725,28.74725,0,0,0,4.06134-22.29065c-.17951-.73212,1.13049-1.03693,1.30975-.30567a29.92178,29.92178,0,0,1-4.2785,23.19165C344.26727,682.76449,343.17706,682.16543,343.61481,681.48178Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M315.50966,623.64456a11.29458,11.29458,0,0,0,8.18139-5.03729c.43058-.69135,1.52039-.09143,1.09261.59533a12.656,12.656,0,0,1-9.03859,5.58954c-.36171.04346-.69923-.08392-.77258-.421a.65808.65808,0,0,1,.53717-.72662Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M379.84213,322.06219l34.77136,36.92163,80.93167,29.66626,9.72535-18.067L442.99057,337.466s-28.07855-29.33591-44.73642-35.37872Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#a0616a"
          />
          <circle cx="270.91225" cy="238.4081" r="12" fill="#a0616a" />
          <path
            d="M406.82385,304.13937l-24.85287,21.944-.117.03c-11.55484,2.9498-28.24353-10.67893-40.20846-22.63156a22.004,22.004,0,0,1-1.01337-30.02384h0a22.0526,22.0526,0,0,1,22.30484-6.666l20.2005,5.57272Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#3f3d56"
          />
          <polygon
            points="201.586 597.14 218.714 597.139 226.862 531.074 201.583 531.075 201.586 597.14"
            fill="#a0616a"
          />
          <path
            d="M430.24911,734.41122l4.821-.00018,18.82211-7.65448,10.08807,7.65332h.00138a21.49726,21.49726,0,0,1,21.49615,21.49585v.69855l-55.22769.00207Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#2f2e41"
          />
          <polygon
            points="125.586 597.14 142.714 597.139 150.862 531.074 125.583 531.075 125.586 597.14"
            fill="#a0616a"
          />
          <path
            d="M354.24911,734.41122l4.821-.00018,18.82211-7.65448,10.08807,7.65332h.00138a21.49726,21.49726,0,0,1,21.49615,21.49585v.69855l-55.22769.00207Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#2f2e41"
          />
          <path
            d="M332.50146,455.43737s-2,24,3,47,5,87.77561,5,87.77561l11,126.22443,33-3,9-211,32,71-3,10,3.99607,2.5s-2.99607,19.5-.99607,19.5,7,3,7,3l-4,103,34-1,12-145-13-83-1-16-4.5.185-2.29135-6.685-4.20865-11.5Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#2f2e41"
          />
          <path
            d="M330.773,460.47839l-.17822-.25c-9.51856-13.32617,4.79736-45.34863,6.95654-49.99072l-9.09424-2.95117,2.04785-17.40772.97022-7.76074-6.95069-6.95068-.01953-.17334-4-35L314.689,316.74737a51.03664,51.03664,0,0,1,14.48584-49.46045l13.44336-12.67529,16.33789-24.17426h26.34668l9.0337,17.06348a38.40114,38.40114,0,0,1,29.71191,39.207L422.001,331.46029l8.01513,55.72071.00489,10.084,9.02,10.02441-1.96972,9.84815,6.86474,2.94189,3.03125,14.14454c1.2544,1.65527,9.53418,12.69531,9.53418,15.71337,0,.45313-.33447.89112-1.05224,1.377-5.64258,3.8208-36.83008,11.61035-50.6333,6.08691-14.70313-5.8789-73.15039,2.94043-73.73926,3.03028Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#3f3d56"
          />
          <path
            d="M546.83673,756.24539a1.18646,1.18646,0,0,1-1.18292,1.19H265.35666a1.19,1.19,0,0,1,0-2.38h280.29a1.1865,1.1865,0,0,1,1.19006,1.18292Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#ccc"
          />
          <circle cx="142.50016" cy="44.87737" r="34.89287" fill="#a0616a" />
          <path
            d="M378.87555,223.19857a94.8982,94.8982,0,0,1-16.10889-1.54443c-5.26269-1.2124-16.874-10.19092-20.03857-15.49512h0c-2.35108-3.94091-3.76221-10.03663-4.53223-14.45605a42.42143,42.42143,0,0,1,8.2861-33.4746,13.40241,13.40241,0,0,1,5.53076-4.479c.19581-.0708.397-.13281.6001-.18506a16.536,16.536,0,0,0,8.35449-4.876,13.63907,13.63907,0,0,1,1.40332-1.37159,20.3685,20.3685,0,0,1,8.60547-4.04638c5.085-1.22119,12.35889.30909,21.61914,4.55224,4.65235,2.13184,9.81592,1.53711,13.7959.78076a1.29551,1.29551,0,0,1,1.2085.44922,9.14508,9.14508,0,0,0,6.14941,2.63721c1.41309.09863,2.84668,2.0957,4.57471,4.69629.39209.59033.84961,1.2793,1.18164,1.69971l.06641-.78955.77734.90283a11.09724,11.09724,0,0,1-5.4751,17.94433,21.64051,21.64051,0,0,1-4.36914.58106c-.89258.05713-1.81592.11622-2.70263.2466a16.731,16.731,0,0,0-12.709,10.53271,4.37552,4.37552,0,0,1-6.65674,1.95313,7.22919,7.22919,0,0,0-6.5542-1.36915,4.799,4.799,0,0,0-2.18261,2.35059,6.25413,6.25413,0,0,1-2.44385,2.70654c-3.63281,1.75585-4.09131,9.938-2.98,16.79737,1.07129,6.61181,3.40185,11.43506,5.66748,11.729,1.74414.22607,1.94092.35547,2.05957.668l.106.27979-.20313.2539C381.70807,223.09944,380.55279,223.19857,378.87555,223.19857Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#2f2e41"
          />
          <circle cx="317.01601" cy="283.08976" r="61.78912" fill="#6c63ff" />
          <path
            d="M544.65439,450.17074c-.13343,0-.26847-.0032-.40351-.00964a8.22978,8.22978,0,0,1-6.09906-3.17011l-9.73855-12.51962a8.245,8.245,0,0,1,1.44357-11.55829l.34885-.27166a8.2497,8.2497,0,0,1,11.56152,1.442,4.94015,4.94015,0,0,0,3.662,1.90335,4.84582,4.84582,0,0,0,3.83078-1.53683l19.77289-20.87244a8.24663,8.24663,0,0,1,11.64349-.31509l.3215.30383a8.24589,8.24589,0,0,1,.31668,11.64511l-30.68335,32.38736A8.22274,8.22274,0,0,1,544.65439,450.17074Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M386.91244,323.34521l24.23932,44.55008L481.879,417.16684l14-15-51.8111-47.86591S424.37234,318.78918,409.7987,308.709Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#a0616a"
          />
          <circle cx="258.84627" cy="269.30341" r="12" fill="#a0616a" />
          <path
            d="M389.0943,329.284l-15.24268-36.583,5.51319-20.2168A22.05492,22.05492,0,0,1,396.47858,256.702h0a22.0029,22.0029,0,0,1,25.30517,16.19c4.17481,16.38965,7.38086,37.69629-1.05127,46.12842l-.08545.08545Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#3f3d56"
          />
          <path
            d="M729.05829,256.05861c-.05489,0-.11044-.00131-.166-.004a3.3857,3.3857,0,0,1-2.50913-1.30417L722.37676,249.6a3.392,3.392,0,0,1,.59388-4.755l.14352-.11176a3.39389,3.39389,0,0,1,4.75636.59322,2.03234,2.03234,0,0,0,1.50652.783,1.99357,1.99357,0,0,0,1.576-.63224l8.13448-8.58684a3.39264,3.39264,0,0,1,4.79009-.12962l.13226.125a3.39232,3.39232,0,0,1,.13028,4.79075l-12.623,13.324A3.38276,3.38276,0,0,1,729.05829,256.05861Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#fff"
          />
          <path
            d="M919.99572,632.47477c7.14923,4.85291,15.096,10.31348,17.3999,19.171a14.56871,14.56871,0,0,1-1.48437,11.33524c-1.83454,3.05807-4.56787,5.46054-7.23407,7.77585-3.16736,2.75051-6.35974,5.45391-8.8172,8.88827-1.12537,1.57276,1.4754,3.07239,2.59039,1.51416,4.55591-6.36694,12.127-9.97357,16.13654-16.8063,4.74188-8.08081,1.57745-17.6641-4.29908-24.206-3.66729-4.08246-8.2658-7.19989-12.778-10.26272-1.60162-1.08716-3.10431,1.511-1.51416,2.59042Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#e6e7e8"
          />
          <path
            d="M944.99572,632.47477c7.14923,4.85291,15.096,10.31348,17.3999,19.171a14.56871,14.56871,0,0,1-1.48437,11.33524c-1.8346,3.05807-4.56787,5.46054-7.234,7.77585-3.16736,2.75051-6.35974,5.45391-8.8172,8.88827-1.12537,1.57276,1.4754,3.07239,2.59039,1.51416,4.55591-6.36694,12.12695-9.97357,16.13648-16.8063,4.74194-8.08081,1.57751-17.6641-4.29908-24.206-3.66723-4.08246-8.2658-7.19989-12.778-10.26272-1.60162-1.08716-3.10431,1.511-1.51416,2.59042Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#e6e7e8"
          />
          <path
            d="M707.02637,412.62875c1.59015-1.07944.08746-3.67759-1.51416-2.59043-4.51214,3.06284-9.11066,6.18027-12.778,10.26273-5.87653,6.5419-9.041,16.12518-4.29907,24.206,4.00952,6.83273,11.58062,10.43936,16.13653,16.80631,1.115,1.55822,3.71576.05859,2.59039-1.51416-2.45745-3.43436-5.64984-6.13776-8.8172-8.88828-2.66619-2.31531-5.39953-4.71777-7.23407-7.77585a14.56869,14.56869,0,0,1-1.48437-11.33523c2.30389-8.85758,10.25067-14.31815,17.3999-19.171Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#e6e7e8"
          />
          <path
            d="M682.02637,412.62875c1.59015-1.07944.08746-3.67759-1.51416-2.59043-4.51214,3.06284-9.11072,6.18027-12.778,10.26273-5.87659,6.5419-9.041,16.12518-4.29907,24.206,4.00952,6.83273,11.58056,10.43936,16.13647,16.80631,1.115,1.55822,3.71576.05859,2.59039-1.51416-2.45746-3.43436-5.64984-6.13776-8.8172-8.88828-2.66613-2.31531-5.39941-4.71777-7.234-7.77585a14.56869,14.56869,0,0,1-1.48437-11.33523c2.30395-8.85758,10.25067-14.31815,17.3999-19.171Z"
            transform="translate(-233.03271 -142.86341)"
            fill="#e6e7e8"
          />
        </svg>
      </div>
    </div>
  );
}
